import React from 'react';

function AboutStory() {
  return (
    <section>
      <div className="max-w-5xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">

          <div className="max-w-3xl mx-auto">
            <h3 className="h3 mb-3">Our story</h3>
            <p className="text-lg text-gray-600 mb-8">
              We are ambitious indie makers. Indie makers are are dreamers and problem solvers. We enjoy building things from the grown up and getting our hands dirty.
            </p>
            <p className="text-lg text-gray-600 mb-8">
              We've built some cool products that were both successful and failures. As an indie maker with very little time and resources, we know that shortcuts are necessary for success. That is why we made Contect.io. We want to provide every indie maker a fair start and a fighting chance.
            </p>
          </div>

          <div className="sm:flex">
            <figure className="flex flex-shrink-0 max-w-none sm:max-w-xs lg:max-w-none mb-8 sm:mb-0">
              <img className="flex-grow self-start rounded" src={require('../images/about-02.jpg')} width="435" height="326" alt="About us 02" />
            </figure>
            <div className="sm:ml-8 lg:ml-16">
              <h4 className="h4 mb-2">2017 - 2020</h4>
              <p className="text-lg text-gray-600 mb-8">
                Find out if the race is worth starting. See how the competition stacks up. Know if there is an audience. Discover if people are willing to buy your <span>idea</span>.
              </p>
              <div className="flex  mb-8">
                <img className="rounded-full self-start flex-shrink-0 shadow-lg mr-4" src={require('../images/about-logo.png')} width="40" height="40" alt="Logo" />
                <div>
                  <blockquote className="text-gray-600 italic">
                    “ You've got an idea, but is it something that can truly take off? You'll have to validate it to be sure. “
                  </blockquote>
                  <div className="text-sm font-medium text-gray-600 mt-2">
                    <cite className="text-gray-900 not-italic">Micheal Osman</cite> ·
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*<div className="max-w-3xl mx-auto">*/}
          {/*  <p className="text-lg text-gray-600">*/}
          {/*    Aenean sed adipiscing diam donec adipiscing tristique risus nec feugiat auctor urna nunc id cursus metus aliquam eleifend, arcu dictum varius duis at consectetur lorem donec massa sapien, sed risus ultricies tristique nulla aliquet. Morbi tristique senectus et netus et, nibh nisl condimentum id venenatis a condimentum vitae sapien.*/}
          {/*  </p>*/}
          {/*</div>*/}

        </div>
      </div>
    </section>
  );
}

export default AboutStory;
