import React from 'react';
import { Link } from 'react-router-dom';

function HowToEasilyValidateStartupIdeas() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto lg:max-w-none">

            <article>

              {/* Article header */}
              <header className="max-w-3xl mx-auto mb-20">
                {/* Title */}
                <h1 className="h1 text-center mb-4">How To Easily Validate Startup Ideas</h1>
              </header>

              {/* Article content */}
              <div className="lg:flex lg:justify-between" data-sticky-container>

                {/* Sidebar */}
                <aside className="relative hidden lg:block w-64 mr-20 flex-shrink-0">
                  <div data-sticky data-margin-top="100" data-sticky-for="768" data-sticky-wrap>
                    <h4 className="text-lg font-bold leading-snug tracking-tight mb-4">Table of contents</h4>
                    <ul className="font-medium -my-1">
                      <li className="py-1">
                        <a className="flex items-center hover:underline" href="#introduction">
                          <svg className="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Introduction</span>
                        </a>
                      </li>
                      <li className="py-1">
                        <a className="flex items-center hover:underline" href="#operator-one">
                          <svg className="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Google Search Operator 1</span>
                        </a>
                      </li>
                      <li className="py-1">
                        <a className="flex items-center hover:underline" href="#operator-two">
                          <svg className="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Google Search Operator 2</span>
                        </a>
                      </li>
                      <li className="py-1">
                        <a className="flex items-center hover:underline" href="#conclusion">
                          <svg className="w-4 h-4 fill-current text-gray-400 mr-3 flex-shrink-0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.3 8.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM7.3 14.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0zM.3 9.7c-.4-.4-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7 7c-.4.4-1 .4-1.4 0z" />
                          </svg>
                          <span>Conclusion</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </aside>

                {/* Main content */}
                <div>

                  {/* Article meta */}
                  <div className="flex items-center mb-6">
                    <div className="flex flex-shrink-0 mr-3">
                      <a className="relative" href="#0">
                        <span className="absolute inset-0 -m-px" aria-hidden="true"><span className="absolute inset-0 -m-px bg-white rounded-full"></span></span>
                        <img className="relative rounded-full" src={require('../images/news-author-04.jpg')} width="32" height="32" alt="Author 04" />
                      </a>
                    </div>
                    <div>
                      <span className="text-gray-600">By </span>
                      <a className="font-medium hover:underline" href="#0">Micheal Osman</a>
                      <span className="text-gray-600"> · July 17, 2020</span>
                    </div>
                  </div>
                  <hr className="w-16 h-px pt-px bg-gray-200 border-0 mb-6" />

                  {/* Article body */}
                  <div className="text-lg text-gray-600">
                    <p id="introduction" className="mb-8" style={{ scrollMarginTop: '100px' }}>
                      The first step in building a successful product is validating the idea. It is a huge risk building a product without know if there are demand for it. The easiest and cheapest way to validate a start up idea is always cold email people at first. However, from my experience cold emails without context get low response rate. This is a step by step guide to get better leads, and early opinions on any project idea.
                    </p>
                    <figure className="mb-8">
                      <img className="w-full rounded" src={require('../images/startup-idea-board-light-bulb.jpeg')} width="768" height="432" alt="Blog single" />
                    </figure>
                    <h3 id="operator-one" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Google Search Operator 1 (LinkedIn)</h3>
                    <p className="mb-8">
                      We will be using Google Search and some advance operators to find our leads. The magical formula to find potential customers is:
                    </p>
                    <p className="p-4 bg-gray-100 border border-gray-300 rounded mb-8">
                      site:linkedin.com/in/ "NICHE KEYWORD" GATEKEEPER POSITION
                    </p>
                    <p className="mb-8">
                      This operator is searching all profiles on LinkedIn for the specific niche and keyword and the person’s position in that company. Here is an example of how to use this operator.
                    </p>

                    <h4 className="h4 text-gray-900 mb-4">Example 1</h4>
                    <p className="mb-8">
                      Here is an example of how to use this operator.
                    </p>
                    <p className="p-4 bg-gray-100 border border-gray-300 rounded mb-8">
                      site:linkedin.com/in/ "NICHE KEYWORD" GATEKEEPER POSITION
                    </p>
                    <figure className="mb-8">
                      <img className="w-full rounded" src={require('../images/linked-machine-learning-developer-search-results.jpeg')} width="768" height="132" alt="Blog single" />
                    </figure>
                    <p className="mb-8">
                      In example 1, we are searching for all developers that is relevant to machine learning. Which return over 8 million leads I could contact to get highly-relevant opinions on my machine learning project. This strategy can also be adopted and use for closing deals and getting leads on existing products.
                    </p>
                    <p className="mb-8">
                      Just add 10-20 of the LinkedIn profiles, the more connected they are the more likely they will accept your request. Once they accept your request politely ask them to review your startup idea.
                    </p>
                    <p className="mb-8">
                      From experience, a considerable amount of LinkedIn users accept connection requests within minutes. So you can validate your idea within minutes.
                    </p>
                    <h3 id="operator-two" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Google Search Operator 2 (Email)</h3>
                    <p className="p-4 bg-gray-100 border border-gray-300 rounded mb-8">
                      site:linkedin.com/in/ "NICHE/KEYWORD" @gmail.com
                    </p>
                    <p className="mb-8">
                      This second operator searches for highly targeted LinkedIn accounts with a publicly available Gmail accounts instead. This can be modified for other popular email services such as Outlook or iCloud.
                    </p>
                    <h4 className="h4 text-gray-900 mb-4">Example 2</h4>
                    <p className="mb-8">
                      Here is an example of how to use this operator.
                    </p>
                    <p className="p-4 bg-gray-100 border border-gray-300 rounded mb-8">
                      site:linkedin.com/in/ "blockchain" @gmail
                    </p>
                    <p className="mb-8">
                      In example 2, we are searching everyone that has a public gmail address that is working with blockchain technology.
                    </p>
                    <figure className="mb-8">
                      <img className="w-full rounded" src={require('../images/blockchain-gmail-linkedin-search-results.png')} width="853" height="132" alt="Blog single" />
                    </figure>
                    <p className="mb-8">
                      This time we only get 131 results, but these users are highly targeted. You should be able to validate any startup idea with this method.                    </p>
                    <p className="mb-8">
                      In your email always mention that you saw their email on LinikedIn. In my experience, majority of these contacts are okay with being approached this way. You should expect at least 50-60% response rate from the emails you send.                    </p>
                    <h3 id="conclusion" className="h3 text-gray-900 mb-4" style={{ scrollMarginTop: '100px' }}>Conclusion</h3>
                    <p className="mb-8">
                      This method can be repeated for multiple keywords and niches. However scraping all this by hand can be tedious. Some advance Google search operators will trigger a CAPTCHAs. This can be annoying and will limit how much or how fast you can scrape Google.
                    </p>
                    <p className="mb-8">
                      That is why we created a <a href="https://rapidapi.com/apigeek/api/google-search3">Google Search API</a> which lets you perform unlimited searches without worrying about CAPTCHAs. Our free plan also comes with 100 request/month, so there is no reason for you not to validate your ideas.
                    </p>
                    <p className="mb-8">
                      Hopefully you can apply these methods from this guide to validate your next business idea. Do yourself a favor because it can save you a lot of time and effort.
                    </p>

                    <div>
                      <hr className="w-full h-px pt-px mt-8 bg-gray-200 border-0" />
                      <div className="mt-8">
                        Interested in more tips like this? Check out <a className="text-gray-900 underline" href="https://blog.goog.io/">Web Scraping Blog and Guide</a>.
                                    </div>
                      <div className="mt-6">
                        <Link to="/blog" className="inline-flex items-center text-base text-blue-600 font-medium hover:underline">
                          <svg className="w-3 h-3 fill-current text-blue-400 flex-shrink-0 mr-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                            <path d="M.293 5.282L5 .5l1.414 1.436-3 3.048H12v2.032H3.414l3 3.048L5 11.5.293 6.718a1.027 1.027 0 010-1.436z" />
                          </svg>
                          <span>Back to the blog</span>
                        </Link>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              {/* Article footer */}
            </article>

          </div>

        </div>
      </div>
    </section>
  );
}

export default HowToEasilyValidateStartupIdeas;
