import React from 'react';
import Accordion from '../utils/Accordion';

function Faqs() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-20">
            <h2 className="h2">Questions & Answers</h2>
          </div>

          {/* Faqs */}
          <ul className="max-w-3xl mx-auto pl-12">
            <Accordion title="Do I need coding knowledge to use this product?">
              No. If you choose the Premium plan we will provide the HTML files, and you have to take care of the domain and hosting. If you choose the Elite plan we will take care of the domain and hosting as well.
            </Accordion>
            <Accordion title="Do you have student or non-profit discounts?">
              No. Our products are labor intensive and our current plans are reasonably priced.
            </Accordion>
            <Accordion title="Is there a way to become an Affiliate reseller of this product?">
              No. We do not currently have an affiliate reseller program.
            </Accordion>
            <Accordion title="Do I get to keep the landing pages and domain?" active>
              Yes. You will have access to all the documents, source code, and own the domain. You are also free to continue to work with our team if you decide to continue building the product.
            </Accordion>
            <Accordion title="Do you provide refunds?">
              In most cases, no. The prices pay for the labor required to do market research and design of the test pages. Customers must cancel the oder before the work begins. However, if the work does not meet custom satisfaction the customers can request revisions.
            </Accordion>
            <span className="block border-t border-gray-200" aria-hidden="true"/>
          </ul >

        </div >
      </div >
    </section >
  );
}

export default Faqs;
